<template>
	<div class="uploadFile h100">
		<div class="uploadFile-content">
			<div style="margin: 0 20%">
				<p class="title" :class="{ activeClass: checkVal === 1 }" @click="checkVal = 1">上传教材</p>
				<p class="title" :class="{ activeClass: checkVal === 2 }" @click="checkVal = 2">上传视频</p>
				<p class="line"></p>
			</div>
			<div class="fixHeight">
				<div class="formClass">
					<el-form :model="formInline" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
						<el-form-item label="课程名称" prop="name" class="w40 mr28">
							<el-input v-model.trim="formInline.name" disabled></el-input>
						</el-form-item>
						<el-form-item label="所属科目" prop="trainingSubject" class="w40 mr0">
							<el-input v-model.trim="formInline.trainingSubject" disabled></el-input>
						</el-form-item>
						<el-form-item label="课程编码" prop="courseCode" class="w40 mr28">
							<el-input v-model.trim="formInline.courseCode" disabled></el-input>
						</el-form-item>
						<el-form-item label="课程定价" prop="price" class="w40 mr0">
							<el-input v-model.trim="formInline.price" disabled></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="uploadFile-tables">
					<div class="main-content-title">
						<p>共有 {{ checkVal === 1 ? fileTableData.length : videoTableData.length }} 条结果</p>
						<el-upload action="" multiple :show-file-list="false" :before-upload="beforeUpload" :http-request="uploadURL" :on-error="importError">
							<el-button type="primary" icon="el-icon-upload2">{{ checkVal === 1 ? '上传教材(PDF)' : '上传视频(MP4)' }}</el-button>
						</el-upload>
					</div>
					<el-table
						:data="checkVal === 1 ? fileTableData : videoTableData"
						border
						:loading="loading"
						style="width: 100%"
						ref="strategyTable"
						row-key="id"
					>
						<el-table-column label="序号" prop="sortNum" width="50" class-name="allowDrag"></el-table-column>
						<el-table-column prop="title" :label="checkVal === 1 ? '教材名称' : '视频名称'" show-overflow-tooltip class-name="allowDrag">
						</el-table-column>
						<el-table-column prop="createTime" label="上传时间" width="160" class-name="allowDrag"> </el-table-column>
						<el-table-column prop="byteCount" :label="checkVal === 1 ? '教材大小' : '视频大小'" width="90" class-name="allowDrag">
							<template slot-scope="scope"> {{ scope.row.byteCount > 0 ? (scope.row.byteCount / 1024 / 1024).toFixed(2) : 0 }}MB </template>
						</el-table-column>
						<el-table-column prop="description" label="课时" width="140" class-name="allowDrag">
							<template slot-scope="scope">
								<el-input
									class="inputWidth"
									:class="{ errorClass: scope.row.showError }"
									v-model.trim="scope.row.description"
									placeholder="请输入"
									size="mini"
									@change="changeClassTime($event, scope.row)"
								/>
								<div class="tipsClass" v-if="scope.row.showError">请输入课时</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="90">
							<template slot-scope="scope">
								<el-button @click="delFile(scope.row)" size="mini" type="danger"> 删除 </el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
		<div class="footerClass">
			<!-- 保 存 -->
			<el-button :loading="saveLoading" type="primary" @click="onSave">保 存</el-button>
			<!-- 重置按钮 -->
			<el-button @click="onReset">取 消</el-button>
		</div>
	</div>
</template>

<script>
import { client, headers, getFileNameUUID } from '@/utils/alioss.js';
import { timeToString } from '@/utils/formatTime.js';
import Sortable from 'sortablejs';
export default {
	data() {
		return {
			checkVal: 1,
			formInline: {},
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				trainingSubject: [{ required: true, message: '请输入', trigger: 'blur' }],
				courseCode: [{ required: true, message: '请输入', trigger: 'blur' }],
				price: [{ required: true, message: '请输入', trigger: 'blur' }],
			},
			loading: false,
			fileTableData: [],
			videoTableData: [],
			total: 0,
			saveLoading: false,
			attachmentInfo: {},
		};
	},

	components: {
		Table: () => import('@/components/Table/table'),
	},
	mounted() {
		this.getFileTeaching();
		this.getFileInfo();
		if (!this.$route.query) return;
		this.formInline = { ...this.$route.query };
		this.formInline.price = this.formInline.price / 100;
	},
	methods: {
		// 获取附件信息
		getFileInfo() {
			this.$http
				.get(this.api.getCourses + `/${this.$route.query.id}.json`)
				.then((res) => {
					if (res.data && res.data.success) {
						let data = res.data.collection[0] || {};
						this.fileTableData =
							(data.attachmentMap['DOCUMENT'] &&
								data.attachmentMap['DOCUMENT'].map((item, index) => {
									item.sortNum = item.sequenceNumber;
									return item;
								})) ||
							[];

						this.videoTableData =
							(data.attachmentMap['VIDEO'] &&
								data.attachmentMap['VIDEO'].map((item, index) => {
									item.sortNum = item.sequenceNumber;
									return item;
								})) ||
							[];
						this.getTableData();
					}
				})
				.catch((e) => {});
		},
		// 获取附件组
		getFileTeaching() {
			this.$http
				.get(this.api.getAttachmentGroups, { params: { subjectTypeCode: 'TEACHING_MATERIAL' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		getTableData() {
			// 阻止默认行为
			document.body.ondrop = function (event) {
				event.preventDefault();
				event.stopPropagation();
			};
			this.rowDrop(); //行拖拽
		},
		//行拖拽
		rowDrop() {
			const el = this.$refs.strategyTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
			const _this = this;
			Sortable.create(el, {
				handle: '.allowDrag',
				onEnd: (evt) => {
					if (_this.checkVal == 1) {
						const targetRow = _this.fileTableData.splice(evt.oldIndex, 1)[0];
						_this.fileTableData.splice(evt.newIndex, 0, targetRow);
						// 自己的业务逻辑部分
						for (let index in _this.fileTableData) {
							_this.fileTableData[index].sortNum = parseInt(index) + 1;
						}
					} else {
						const checkRow = _this.videoTableData.splice(evt.oldIndex, 1)[0];
						_this.videoTableData.splice(evt.newIndex, 0, checkRow);
						// 自己的业务逻辑部分
						for (let index in _this.videoTableData) {
							_this.videoTableData[index].sortNum = parseInt(index) + 1;
						}
					}
				},
			});
		},
		// 自定义上传
		uploadURL(file) {
			let tableData = this.checkVal === 1 ? this.fileTableData : this.videoTableData;
			let fileName = 'bovinae/zhangl/TEACHING_MATERIAL' + `/${getFileNameUUID()}` + '.png';
			client()
				.multipartUpload(fileName, file.file, {
					headers,
				})
				.then((res) => {
					const path = 'https://qdincrease-cd.oss-cn-chengdu.aliyuncs.com/' + res.name;
					if (res.res.status == 200) {
						this.$message({
							type: 'success',
							message: '导入成功',
						});
						this.$loading().close();
						tableData.push({
							title: file.file.name,
							url: path,
							createTime: timeToString(new Date().getTime()),
							byteCount: file.file.size,
							description: '',
							uid: file.file.uid,
							showError: false,
							id: null,
							isEdit: true,
						});
						tableData = tableData.map((item, index) => {
							item.sortNum = index + 1;
							return item;
						});
					}
				});
		},
		beforeUpload(file) {
			let lastLen = file.name.lastIndexOf('.');
			let len = file.name.len;
			let type = file.name.substring(lastLen, len);
			if (this.checkVal === 1) {
				if (type !== '.pdf') {
					this.$message({
						type: 'error',
						message: '请导入pdf文件',
					});
					return false;
				}
			} else {
				if (type !== '.mp4') {
					this.$message({
						type: 'error',
						message: '请导入mp4文件',
					});
					return false;
				}
			}
			this.$loading({
				lock: true,
				text: '导入中',
				background: 'rgba(0, 0, 0, 0.7)',
				spinner: 'el-icon-loading',
			});
		},
		importError(err, file, fileList) {
			let msg = err.toString().split('"msg":')[1];
			let showMsg = msg.length > 0 ? msg.split('"') : [];
			this.$message({
				type: 'error',
				message: showMsg[1] || '导入失败',
			});
			this.$loading().close();
		},
		// 保存
		onSave() {
			let fileTableData = this.fileTableData.map((item) => {
				return {
					type: 'png',
					sequenceNumber: item.sortNum,
					link: item.url,
					subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'DOCUMENT').subjectTypeCode,
					title: item.title,
					attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'DOCUMENT').id,
					mimeType: item.title.split('.')[1],
					...item,
				};
			});
			let videoTableData = this.videoTableData.map((item) => {
				return {
					type: 'video',
					sequenceNumber: item.sortNum,
					link: item.url,
					subjectTypeCode: this.attachmentInfo.find((item) => item.code == 'VIDEO').subjectTypeCode,
					title: item.title,
					attachmentGroupId: this.attachmentInfo.find((item) => item.code == 'VIDEO').id,
					mimeType: item.title.split('.')[1],
					...item,
				};
			});
			let tableData = [...fileTableData, ...videoTableData];
			tableData.forEach((item) => {
				if (!item.description || item.description === '') {
					item.showError = true;
				}
			});
			let showMsg = tableData.find((item) => item.showError);
			if (showMsg) {
				return this.$message.warning(`${showMsg.type === 'png' ? '教材' : '视频'}课时未填写`);
			} else {
				if (!this.$route.query?.id) return;
				let params = {
					...this.$route.query,
					attachmentList: tableData,
				};
				this.$http
					.put(this.api.getCourses + `/${this.$route.query.id}.json`, params)
					.then((res) => {
						if (res.data && res.data.success) {
							this.$message.success('修改成功');
							this.saveLoading = false;
							this.$router.go(-1);
						}
					})
					.catch((e) => {
						this.saveLoading = false;
					});
			}
		},
		// 取消
		onReset() {
			let tableData = this.checkVal === 1 ? this.fileTableData : this.videoTableData;
			let newAddFile = tableData.filter((item) => item.isEdit);
			if (newAddFile.length > 0) {
				this.$confirm('你所做的更改尚未保存，离开后将丢失当前编辑的内容！', '确定离开此页面吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				})
					.then(() => {
						this.getTableData();
						this.$router.go(-1);
					})
					.catch(() => {});
			} else {
				this.getTableData();
				this.$router.go(-1);
			}
		},
		// 删除
		delFile(row) {
			this.$confirm('确认删除该文件吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					if (this.checkVal === 1) {
						if (row.id) {
							this.fileTableData = this.fileTableData.filter((item) => item.id !== row.id);
							return;
						}
						if (row.uid) {
							this.fileTableData = this.fileTableData.filter((item) => item.uid !== row.uid);
							return;
						}
					} else {
						if (row.id) {
							this.videoTableData = this.videoTableData.filter((item) => item.id !== row.id);
							return;
						}
						if (row.uid) {
							this.videoTableData = this.videoTableData.filter((item) => item.uid !== row.uid);
							return;
						}
					}
				})
				.catch(() => {});
		},
		// 课时
		changeClassTime(val, row) {
			if (val === '') {
				row.showError = true;
			} else {
				row.showError = false;
				row.isEdit = true;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.uploadFile {
	background-color: #fff;
	border-radius: 2px;
	padding-top: 20px;
	margin: 16px 16px 0;
	&-content {
		margin: 0 auto;
		height: calc(100% - 80px);
		.fixHeight {
			width: 100%;
			height: calc(100% - 48px);
			overflow: auto;
			padding: 0 20%;
			padding-bottom: 80px;
		}
		.title {
			font-size: 16px;
			color: #333333;
			display: inline-block;
			width: 66px;
			margin-right: 28px;
			line-height: 44px;
			cursor: pointer;
		}
		.activeClass {
			color: #1db9b1;
			border-bottom: 3px solid #1db9b1;
			box-sizing: border-box;
		}
		.line {
			width: 100%;
			height: 2px;
			background-color: #f3f3f3;
		}
		.formClass {
			margin-top: 40px;
			/deep/ .el-form-item {
				margin-bottom: 28px;
				.el-form-item__label {
					line-height: 14px;
					height: 14px;
					margin-bottom: 8px;
				}
			}
		}
		.uploadFile-tables {
			margin-top: 32px;
			.main-content-title {
				line-height: 48px;
				display: flex;
				justify-content: space-between;
				.el-button {
					line-height: 32px;
					padding: 0 16px;
					height: 32px;
					margin-top: 8px;
				}
			}
		}
	}
	.footerClass {
		line-height: 80px;
		border-top: 1px solid rgba(0, 0, 0, 0.08);
		text-align: center;
	}

	/deep/ .el-table {
		.cell {
			line-height: 20px;
			text-align: center;
		}
		tr th {
			background-color: #f5f5f5;
		}
		.el-table__cell {
			color: #333 !important;
			font-weight: normal;
			height: 20px;
			line-height: 20px;
			padding: 16px 0;
			.caret-wrapper {
				height: 20px;
				.ascending {
					top: -3px;
				}
				.descending {
					bottom: 1px;
				}
			}
		}
		.inputWidth {
			width: 110px;
			position: relative;
		}
		.errorClass {
			.el-input__inner {
				border-color: #f56c6c !important;
			}
		}
		.tipsClass {
			position: absolute;
			color: #f56c6c;
			text-align: left;
			font-size: 12px;
			line-height: 12px;
			left: 14px;
			top: 45px;
		}
	}
	/deep/ .w40 {
		width: calc(50% - 14px);
		display: inline-block;
	}
	/deep/ .m28 {
		margin-right: 28px;
	}
	/deep/ .mr0 {
		margin-right: 0 !important;
	}
}
</style>